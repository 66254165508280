import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import style from './style'
import { useLayout } from '../../utils/layout-context'
import { imagePropTypes, structuredTextPropTypes } from '../../utils/prop-types'
import { useRouter } from 'next/router'
import appConstants from '../../utils/constants/vfi-constants'
import Image from 'next/image'
import { motion } from 'framer-motion'
import {
  vBounce,
  vSlideRightFadeCustom,
  vTextFade,
} from '../../utils/animations'
import { useSnackbar } from 'notistack'
import { linkResolver } from '../../prismicio'

const Subscribe = ({ slice, classes }) => {
  const { primary } = slice
  const { layout } = useLayout()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const router = useRouter()
  const locale = router.locale

  const { enqueueSnackbar } = useSnackbar()

  const reset = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setEmailValid(false)
  }

  const send = async ({ email, firstName, lastName }) => {
    if (emailValid) {
      const result = await fetch('api/contact/mailing-list', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstName, lastName, twoLetterLanguageCode: locale }),
      })

      if (result.status === 200) {
        reset()
        enqueueSnackbar(RichText.asText(layout.subscribe_success_message), {
          variant: 'success',
        })

        router.push(linkResolver(primary.thankyouPageLink))
      } else if (result.status === 400) {
        enqueueSnackbar(layout.subscribe_double_message, {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar(RichText.asText(layout.subscribe_error_message), {
          variant: 'error',
        })
      }
    } else {
      enqueueSnackbar(RichText.asText(layout.subscribe_email_error_message), {
        variant: 'warning',
      })
    }
  }

  const emailValidation = (e) => {
    setEmailValid(appConstants.emailRegex.test(e.target.value))
  }

  const viewport = {
    once: true,
    margin: '-6%',
  }

  return (
    <>
      <div className={classes.subscribe}>
        <Container maxWidth="lg">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
              {primary.resourceImage?.url && (
                <motion.div
                  variants={vBounce}
                  initial={'offscreen'}
                  animate={'onscreen'}
                  viewport={viewport}
                >
                  <Image
                    src={primary.resourceImage.url}
                    alt={primary.resourceImage.alt || ''}
                    className="resourceImage"
                    width={primary.resourceImage.dimensions.width}
                    height={primary.resourceImage.dimensions.height}
                    sizes={'100vw'}
                  />
                </motion.div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                variants={vTextFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
                transition={{
                  type: 'tween',
                  delay: 0.5,
                  duration: 0.8,
                }}
              >
                <Typography variant="h3" className="title">
                  {RichText.asText(primary.title)}
                </Typography>
              </motion.div>
              <motion.div
                variants={vTextFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
                transition={{
                  type: 'tween',
                  delay: 0.8,
                  duration: 0.8,
                }}
              >
                <Typography variant="subtitle2" className="description">
                  {RichText.asText(primary.description)}
                </Typography>
              </motion.div>
              <motion.div
                variants={vSlideRightFadeCustom}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
                transition={{
                  type: 'tween',
                  delay: 0.8,
                  duration: 0.8,
                }}
              >
                <form
                  onSubmit={(formData) => {
                    formData.preventDefault()
                    send({ email, firstName, lastName })
                  }}
                >
                  <Paper className="subscribeWrapper splitWrapper">
                    <InputBase
                      className="input"
                      placeholder={RichText.asText(
                        layout.first_name_placeholder_contact
                      )}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      type="text"
                    />
                    <InputBase
                      className="input"
                      placeholder={RichText.asText(
                        layout.last_name_placeholder_contact
                      )}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      type="text"
                    />
                  </Paper>
                  <Paper className="subscribeWrapper">
                    <InputBase
                      className="input"
                      placeholder={RichText.asText(primary.emailPlaceholder)}
                      onChange={(e) => {
                        setEmail(e.target.value)
                        emailValidation(e)
                      }}
                      value={email}
                      type="email"
                    />
                    <Button
                      color="secondary"
                      variant="contained"
                      className="subButton"
                      size="large"
                      aria-label="directions"
                      type="submit"
                    >
                      {RichText.asText(primary.subscribeButtonText)}
                    </Button>
                  </Paper>
                </form>
              </motion.div>
              <div className="privacyText">
                <motion.div
                  variants={vTextFade}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={viewport}
                  transition={{
                    type: 'tween',
                    delay: 1,
                    duration: 0.8,
                  }}
                >
                  {RichText.render(primary.privacyText)}
                </motion.div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

Subscribe.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      resourceImage: imagePropTypes,
      title: structuredTextPropTypes,
      description: structuredTextPropTypes,
      emailPlaceholder: structuredTextPropTypes,
      subscribeButtonText: structuredTextPropTypes,
      privacyText: structuredTextPropTypes,
      thankyouPageLink: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(style)(Subscribe)
